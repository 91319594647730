<template>
  <div class="bg-white p-3">
    <div class="row">
      <div class="col">
        <h3>RESULTADOS DIARIOS</h3>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <form class="form-inline">
          <label for="" class="mr-2">Desde: </label>
          <input
            type="date"
            v-model="date_ini"
            class="form-control mr-2"
            @change="obtenerDatos()"
          />
          <label for="" class="mr-2">Hasta: </label>
          <input
            type="date"
            v-model="date_end"
            class="form-control"
            @change="obtenerDatos()"
          />
        </form>
        <canvas id="source_inputs" width="100" height="100"></canvas>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card-body">
          <h5>Resumen de Flujo de Caja</h5>
          <div class="row">
            <div class="col">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th>Fuente de Ingreso</th>
                    <th class="text-right">SubTotal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="l in list.inputs"
                    :key="l.money_code + l.source_name"
                  >
                    <td>{{ l.source_name }}</td>
                    <td class="text-right">
                      <app-span-money
                        :quantity="l.quantity"
                        :moneyCode="l.money_code"
                      ></app-span-money>
                    </td>
                  </tr>
                  <tr v-for="l in list.input_total" :key="l.money_code">
                    <th>Total Ingresos</th>
                    <th class="text-right">
                      <app-span-money
                        :quantity="l.quantity"
                        :moneyCode="l.money_code"
                      ></app-span-money>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th>Razón de Egreso</th>
                    <th class="text-right">SubTotal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="l in list.outputs" :key="l.reason_name">
                    <td>{{ l.reason_name }}</td>
                    <td class="text-right">
                      <app-span-money
                        :quantity="l.quantity"
                        :moneyCode="l.money_code"
                      ></app-span-money>
                    </td>
                  </tr>
                  <tr v-for="l in list.output_total" :key="l.money_code">
                    <th>Total Egresos</th>
                    <th class="text-right">
                      <app-span-money
                        :quantity="l.quantity"
                        :moneyCode="l.money_code"
                      ></app-span-money>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import _ from "lodash";
import { AdminService } from "../AdminService";

var ctx = {};
var barChartData = {};
var barChart = {};

export default {
  data() {
    return {
      date_ini: "",
      date_end: "",
      list: {}
    };
  },
  created() {
    var today = new Date();
    this.date_ini =
      today.getFullYear() +
      "-" +
      _.padStart(today.getMonth() + 1, 2, "0") +
      "-" +
      _.padStart(today.getDate(), 2, "0");
    this.date_end =
      today.getFullYear() +
      "-" +
      _.padStart(today.getMonth() + 1, 2, "0") +
      "-" +
      _.padStart(today.getDate(), 2, "0");

    
  },
  mounted() {
    ctx = document.getElementById("source_inputs").getContext("2d");
    barChart = new Chart(ctx, {
      type: "horizontalBar",
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        },
        responsive: true,
        title: {
          display: true,
          text: "Ingresos por Fuente de Ingreso"
        },
        tooltips: {
          mode: "index",
          intersect: true
        }
      }
    });

    this.obtenerDatos();
  },
  methods: {
    obtenerDatos() {
      AdminService.getDayresults({
        params: {
          date_ini: this.date_ini,
          date_end: this.date_end
        }
      }).then(res => {
        this.list = res;
        this.dibujar1(res.inputs);
      });
    },
    dibujar1(data) {
      var labels = data.map(x => x.source_name.toUpperCase());
      barChartData = {
        labels,
        datasets: [
          {
            type: "horizontalBar",
            label: "Ingresos",
            data: data.map(x => x.quantity)
          },
          {
            type: "line",
            label: "Ingresos",
            data: data.map(x => x.quantity)
          }
        ]
      };

      barChart.data = barChartData;
      barChart.update();
    }
  }
};
</script>

<style></style>
c  
